import { Component, OnInit, ViewChild, QueryList, ElementRef } from '@angular/core';
import { AngularFirestoreCollection, AngularFirestore } from 'angularfire2/firestore';
import { FirestoreService } from 'src/app/firestore.service';
import { AuthenticationService } from 'src/app/authentication.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit {

  category = this.router.url.split('/')[1];
  PDFUrl: string;

  constructor(public router: Router) {
    this.PDFUrl = (this.category === "saisonales") ? '/assets/Preisliste-SPECIALS.pdf' : '/assets/Preisliste-STANDARD.pdf';
  }

  ngOnInit() {
  }

  isSaison(url) {
    const reg = /(xmas|ostern|valentin)/g;
    // console.log(url.match(reg))
    return url.match(reg);
  }

}
