import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pageTitle'
})
export class PageTitlePipe implements PipeTransform {
  private routes = {
    "/ueber": "über <em>bitter</em> süss",
    "/ueber/kontakt": "kontakt",
    "/ueber/impressionen": "impressionen",
    "/ueber/auszeichnungen": "auszeichnungen",
    "/ueber/360": "360°",
    "/ueber/filme": "filme",
    "/ueber/presse": "presse",
    "/kurse": "kurse",
    "/ueber/impressum": "impressum",
    "/ueber/bio-nachhaltigkeit": "bio & Nachhaltigkeit",
    "/ueber/allergene": "allergene",
    "/ueber/haftungsausschluss": "haftungsausschluss",
    "/ueber/datenschutzerklaerung": "datenschutzerklärung",
    "/firmenkunden": "firmenkunden"
  }
  transform(value: any, args?: any): any {
    return this.routes[value];
  }

}
