import { take, takeLast } from 'rxjs/operators';
import { Component, OnInit, Input, ViewChild, QueryList, ElementRef, ViewChildren, ViewContainerRef, TemplateRef, Query, Renderer2 } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Product } from 'src/app/cp/Product';
import { FirestoreService } from 'src/app/firestore.service';
import { AngularFirestore } from 'angularfire2/firestore';
import { Router } from '@angular/router';
import { ScrollingService } from '../../scrolling.service';
import * as Waypoint from "../../../../../node_modules/waypoints/lib/noframework.waypoints.min.js";
declare var Waypoint: any;

@Component({
  selector: 'products-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit {
  items$: Observable<any>;
  category = this.router.url.split("/")[1];
  itemsCount;
  itemRest = [];
  waypoints;
  isSaison = false;
  saison;
  scrollTo: string = localStorage.getItem("scrollTo");

  constructor(private db: FirestoreService, private afs: AngularFirestore, private router: Router, private scrolling: ScrollingService, private el: ElementRef,
    private renderer: Renderer2) {

  }

  ngOnInit() {
    if (this.category === "saisonales") {
      this.isSaison = true;
      this.saison = this.db.getSaison(false);
      this.saison = this.saison.filter(item => item.slug === this.router.url.split("/")[2])[0];

    } else {
      this.items$ = this.db.productsByCategory(this.category, false);
      this.items$.subscribe(data => {
        this.itemsCount = data.length;
        for (let i = 0; i < this.itemsCount; i++) {
          this.itemRest.push("");
        }

      })
    }

  }


  ngAfterViewInit(): void {
    setTimeout(() => {
      this.scrolling.triggerScrollTo(this.scrollTo)
    }, 450);
  }

  listen() {
    console.log("ay")
  }

  inView(inViewport: boolean, slug) {
    this.scrolling.inView(inViewport, slug);
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
  }
}
