import {News} from './../../cp/news/News';
import {Component, OnInit} from '@angular/core';
import {FirestoreService} from 'src/app/firestore.service';
import {Observable} from 'rxjs';
import {Router} from '@angular/router';
import {AuthenticationService} from '../../authentication.service';
import {AngularFirestore} from 'angularfire2/firestore';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  news$: Observable<News[]>;
  show = true;
  hasShopLink: Observable<any>;
  shopLink: string;

  constructor(private firestore: FirestoreService, public router: Router, public auth: AuthenticationService, private afs: AngularFirestore) {
    this.hasShopLink = this.afs.collection('meta').valueChanges();
    this.hasShopLink.subscribe(data => {
      if (data.length > 0) {
        this.shopLink = data[0].shopLink;
      }
    });
  }

  ngOnInit() {
    this.news$ = this.firestore.getNews();
  }
}
