import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'cp-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent implements OnInit {
  uGroup = JSON.parse(localStorage.getItem("userGroup"));
  constructor() { }

  ngOnInit() {
    console.log(this.uGroup)
  }

}
