import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { GlobalModule } from 'src/app/global/global.module';
import { NavComponent } from './nav/nav.component';
import { IntroComponent } from './intro/intro.component';

@NgModule({
  declarations: [NavComponent, IntroComponent],
  imports: [
    CommonModule,
    RouterModule,
    GlobalModule
  ],
  exports: [NavComponent]
})
export class FirmenkundenModule { }
