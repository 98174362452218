import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImpressionenComponent } from './impressionen/impressionen.component';
import { AuszeichnungenComponent } from './auszeichnungen/auszeichnungen.component';
import { ThreeSixtyComponent } from './three-sixty/three-sixty.component';
import { FilmeComponent } from './filme/filme.component';
import { PresseComponent } from './presse/presse.component';
import { GesaComponent } from './gesa/gesa.component';
import { SideNavComponent } from './side-nav/side-nav.component';
import { RouterModule } from '@angular/router';
import { GlobalModule } from 'src/app/global/global.module';
import { ImpressumComponent } from './impressum/impressum.component';
import { AllergeneComponent } from './allergene/allergene.component';
import { HaftungsausschlussComponent } from './haftungsausschluss/haftungsausschluss.component';
import { DatenschutzerklaerungComponent } from './datenschutzerklaerung/datenschutzerklaerung.component';

@NgModule({
  declarations: [
    ImpressionenComponent,
    AuszeichnungenComponent,
    ThreeSixtyComponent,
    FilmeComponent,
    PresseComponent,
    GesaComponent,
    SideNavComponent,
    ImpressumComponent,
    AllergeneComponent,
    HaftungsausschlussComponent,
    DatenschutzerklaerungComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    GlobalModule
  ],
  exports: [
    ImpressionenComponent,
    AuszeichnungenComponent,
    ThreeSixtyComponent,
    FilmeComponent,
    PresseComponent,
    GesaComponent,
    SideNavComponent,
  ]
})
export class UeberModule { }
