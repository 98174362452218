import {Component, OnInit, ViewChild, ElementRef} from '@angular/core';
import {Router} from '@angular/router';
import {AngularFirestore, AngularFirestoreCollection} from 'angularfire2/firestore';
import {Product} from '../../../cp/Product';
import {Observable} from 'rxjs';
import {ScrollingService} from '../../scrolling.service';
import {takeLast, take, map} from 'rxjs/operators';
import {FirestoreService} from 'src/app/firestore.service';

@Component({
    selector: 'product-nav',
    templateUrl: './side-nav.component.html',
    styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent implements OnInit {
    itemsCollection: AngularFirestoreCollection<Product>;
    items$: Observable<any>;
    itemsExtraColl: AngularFirestoreCollection<Product>;
    itemsExtraLength: number;
    itemsExtra$: Observable<Product[]>;
    category = this.router.url.split('/')[1];
    mobile = false;
    active: string;
    isSaison: boolean;
    saison;
    @ViewChild('aside') aside: ElementRef;
    @ViewChild('navlist') navlist: ElementRef;
    contentHeight: number;

    constructor(private afs: AngularFirestore, public router: Router, private scrolling: ScrollingService, private db: FirestoreService) {
        this.itemsCollection = this.afs.collection<Product>(this.category, ref => ref.where('overview', '==', true).orderBy('order', 'asc'));
        this.items$ = this.itemsCollection.valueChanges();
        this.itemsExtraColl = this.afs.collection<Product>(this.category, ref => ref.where('extra', '==', true).orderBy('order', 'asc'));
        this.itemsExtra$ = this.itemsExtraColl.valueChanges();
        this.itemsExtra$.subscribe(e => {
            this.itemsExtraLength = e.length;
        });
        if (this.category === 'saisonales') {
            this.isSaison = true;
            this.saison = this.db.getSaison(false);
        }
    }

    ngOnInit() {
        // this.scrolling.inViewportChange.subscribe(data => {
        //   console.log(data)
        //   this.active = data;
        // });
        window.addEventListener('scroll', this.scrollEvent, true);

    }

    scrollEvent = (event: any, initial?: string): void => {
        const bod = event ? event.target : document;
        const els = Array.from(bod.querySelectorAll('.solo-cnt__item'));
        const navClass = (this.isSaison) ? 'sub--list-item' : 'main__menu__list__item';
        const navActiveClass = (this.isSaison) ? 'sub__active' : 'menu__active';
        if (this.router.url.includes('liste') || this.router.url.includes('saison')) {
            els.forEach((el: any) => {
                if (el && this.isInViewport(el)) {
                    const active = bod.querySelector(`.${navClass}.${navActiveClass}`);
                    if (active) {
                        active.classList.remove(navActiveClass);
                    }
                    bod.querySelector(`.${navClass}[data-ref='${el.id}']`).classList.add(navActiveClass);
                }
            });
        }
    };

    isInViewport(elem) {
        let bounding = elem.getBoundingClientRect();
        let calc = (window.innerWidth <= 768) ? (bounding.bottom) : (bounding.bottom - 200);
        return (
            bounding.top >= 0 &&
            bounding.left >= 0 &&
            (calc) <= (window.innerHeight || document.documentElement.clientHeight) &&
            bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
    };

    isSchoko() {
        return this.router.url.includes('schokolade') || !this.mobile;
    }

    scrollTo(destination) {
        this.mobile = false;
        this.scrolling.triggerScrollTo(destination);
    }

    goTo(slug) {
        localStorage.setItem('scrollTo', slug);
        this.router.navigate([`${this.category}/liste`]);
    }

    ngOnDestroy(): void {
        console.log('rm');
        window.addEventListener('scroll', () => {
            return false;
        }, false)
    }

}
