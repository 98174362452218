import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { GlobalModule } from 'src/app/global/global.module';
import { Catalog } from './catalog/catalog.component';

@NgModule({
  declarations: [Catalog],
  imports: [
    CommonModule,
    RouterModule,
    GlobalModule
  ],
  exports: [Catalog],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class CoursesModule { }
