import { Product } from './cp/Product';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AuthenticationService } from './authentication.service';
import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { Course } from './cp/courses/Course';
import { News } from './cp/news/News';

@Injectable({
  providedIn: 'root'
})
export class FirestoreService {

  constructor(private firestore: AngularFirestore, private auth: AuthenticationService) {
    this.auth.login("hi@xschoenberger.com", "!bittersuess.xYz0!");
  }
  productsByCategory(category: string, overview: boolean, saison?: boolean) {
    category = (category === "saisonales") ? "saison" : category;
    const productsCollection = (overview) ? this.firestore.collection<Product>(category, ref => ref.where("overview", "==", true).orderBy("order", "asc")) : (saison) ? this.firestore.collection<Product>(category, ref => ref.where("overview", "==", false).orderBy("order", "asc")) : this.firestore.collection<Product>(category, ref => ref.orderBy("order", "asc"));
    const products$ = productsCollection.valueChanges();
    return products$;
  }
  addProduct(data: Product, category: string) {
    const id = this.firestore.createId();
    console.log(id)
    data.id = id;
    return new Promise<any>((resolve, reject) => {
      this.firestore
        .collection(category)
        .add(data)
        .then(res => { console.log(res) }, err => reject(err));
    });
  }
  getSaison(overview: boolean) {
    const obj = [
      { title: "X-Mas", slug: "xmas", items: [] },
      { title: "Valentin", slug: "valentin", items: [] },
      { title: "Ostern", slug: "ostern", items: [] }
    ];
    if (overview) {
      this.firestore.collection<Product>("saison", ref => ref.orderBy("order", "asc")).valueChanges().subscribe(data => {
        data.map(item => {
          const arr = obj.filter(category => !!item.saison && category.title === item.saison)[0];
          arr.items.push(item);
        })
      })
    } else {
      this.firestore.collection<Product>("saison", ref => ref.where("overview", "==", false).orderBy("order", "asc")).valueChanges().subscribe(data => {
        data.map(item => {
          const arr = obj.filter(category => !!item.saison && category.title === item.saison)[0];
          arr.items.push(item);
        })
      })
    }
    return obj;
  }
  // addSaison(data: Product, doc: string) {
  //   const id = this.firestore.createId();
  //   console.log(id)
  //   data.id = id;
  //   return new Promise<any>((resolve, reject) => {
  //     this.firestore
  //       .collection("saison").doc(doc).collection("items")
  //       .add(data)
  //       .then(res => { console.log(res) }, err => reject(err));
  //   });
  // }
  addCourse(data: Course) {
    const id = this.firestore.createId();
    console.log(id)
    data.id = id;
    return new Promise<any>((resolve, reject) => {
      this.firestore
        .collection("kurse")
        .add(data)
        .then(res => { console.log(res) }, err => reject(err));
    });
  }
  postNews(data: News) {
    return new Promise<any>((resolve, reject) => {
      this.firestore
        .collection("news")
        .add(data)
        .then(res => { console.log(res) }, err => reject(err));
    });
  }
  getNews() {
    const newsCollection = this.firestore.collection<News>("news");
    const news$ = newsCollection.valueChanges();
    return news$;
  }
  allCourses() {
    const courseCollection = this.firestore.collection<Course>("kurse");
    const courses$ = courseCollection.valueChanges();
    return courses$;
  }
  allProducts() {
    const courseCollection = this.firestore.collection<Course>("pralinen");
    const courses$ = courseCollection.valueChanges();
    return courses$;
  }


}
