import { Date } from './../Course';
import { Component, OnInit } from '@angular/core';
import { FirestoreService } from 'src/app/firestore.service';
import { AngularFirestore } from 'angularfire2/firestore';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';

@Component({
  selector: 'app-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.scss']
})
export class AddComponent implements OnInit {
  title: string;
  subtitle: string;
  dates: Date[] = [];
  time: string;
  description: string = "";
  active: boolean = true;
  errors = [];
  Editor = ClassicEditor;

  constructor(private firestore: FirestoreService, private af: AngularFirestore) { }

  ngOnInit() {
    this.dates.push(
      { date: "", time: "", status: "" }
    )
  }

  addDate() {
    this.dates.push({ date: "", time: "", status: "" });
  }
  removeDate(idx) {
    console.log(idx)
    this.dates.splice(idx, 1);
  }

  postCourse() {
    const course = this.initCourse();
    for (const key in course.dates) {
      if (course.dates[key].status === "") {
        course.dates[key].status = "verfügbar";
      }
    }
    this.validDates();
    this.valid(course);
    if (this.errors.length === 0) {
      this.firestore.addCourse(course);
      this.clear();
    }

    console.log(this.errors);
    console.log(course);
  }
  valid(course) {
    let valid = true;
    for (const key in course) {
      if (key !== "subtitle" && course[key] == undefined || course[key] === "") {
        valid = false;
      }
    }
    if (!valid) this.errors.push("Textfelder dürfen nicht leer sein!");
    return valid;
  }
  validDates() {
    const valid = this.dates.map(i => i.date === "" && i.time === "").filter(Boolean).length <= 0;
    console.log("invalid?", valid);
    if (!valid) {
      this.errors.push("Daten/Datum Felder müssen zur Gänze ausgefüllt sein!")
    }
    return valid;
  }
  initCourse() {
    const id = this.af.createId();

    return {
      id: id,
      title: this.title,
      subtitle: this.subtitle,
      description: this.description,
      dates: this.dates,
      active: this.active
    }
  }
  clear() {
    this.title = "";
    this.subtitle = "";
    this.dates = [];
    this.time = "";
    this.description = "";
    this.active = true;
    this.errors = [];
  }

}
