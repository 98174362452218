import { Injectable } from '@angular/core';
import { ScrollToService, ScrollToConfigOptions, ScrollToDirective } from '@nicky-lenaers/ngx-scroll-to';
import { Subject } from 'rxjs';
import { takeLast } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ScrollingService {
  inViewportChange = new Subject<any>();
  highlight;
  active: string;

  constructor(private _scrollToService: ScrollToService) {

  }

  public lastScroll() {
    console.log(ScrollToDirective)
  }

  public triggerScrollTo(destination) {
    // console.log(destination)

    const config: ScrollToConfigOptions = {
      target: destination,
      duration: 1500,
      easing: 'easeInOutQuart'
    };

    this._scrollToService.scrollTo(config);
  }

  inView(inViewport: boolean, slug) {
    if (inViewport) this.inViewportChange.next(slug);
  }
}
