import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { FormsModule } from "@angular/forms";
import { BrowserModule } from '@angular/platform-browser';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { InViewportModule } from '@thisissoon/angular-inviewport';
import { AngularFireModule } from "angularfire2";
import { AngularFirestoreModule } from "angularfire2/firestore";
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CpComponent } from './cp/cp.component';
import { CpModule } from './cp/cp.module';
import { IsProductPipe } from './is-product.pipe';
import { LoginComponent } from './login/login.component';
import { NavigationComponent } from './navigation/navigation.component';
import { HomeComponent } from './views/home/home.component';
import { ListComponent } from './views/products/list/list.component';
import { ProductsComponent } from './views/products/products.component';
import { KontaktComponent } from './views/ueber/kontakt/kontakt.component';
import { UeberComponent } from './views/ueber/ueber.component';
import { ViewsComponent } from './views/views.component';
import { UeberModule } from './views/ueber/ueber.module';

import { GalleryModule } from '@ngx-gallery/core';
import { LightboxModule } from '@ngx-gallery/lightbox';
import { GallerizeModule } from '@ngx-gallery/gallerize';
import { ProductsModule } from './views/products/products.module';
import { ProductsModule as CpProductsModule } from "./cp/products/products.module";
import { CoursesComponent } from './views/courses/courses.component';
import { TruncatePipe } from './cp/courses/truncate.pipe';
import { GlobalModule } from './global/global.module';
import { FirmenkundenComponent } from './views/firmenkunden/firmenkunden.component';
import { FirmenkundenModule } from './views/firmenkunden/firmenkunden.module';
import { CoursesModule } from './views/courses/courses.module';
import { NavComponent } from './views/courses/nav/nav.component';
import { ExternalLinksPipe } from './external-links.pipe';


@NgModule({
  declarations: [
    AppComponent,
    CpComponent,
    ViewsComponent,
    HomeComponent,
    KontaktComponent,
    NavigationComponent,
    ProductsComponent,
    ListComponent,
    UeberComponent,
    LoginComponent,
    CoursesComponent,
    FirmenkundenComponent,
    NavComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    GlobalModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirestoreModule.enablePersistence(),
    AngularFireAuthModule,
    UeberModule,
    CpModule,
    GalleryModule,
    LightboxModule,
    GallerizeModule,
    FormsModule,
    ProductsModule,
    CpProductsModule,
    InViewportModule,
    FirmenkundenModule,
    CoursesModule,
    ScrollToModule.forRoot()
  ],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule { }
