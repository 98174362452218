// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyCSFw-uPepNH0oGldjhx5V7CLz9IjQjHDE",
    authDomain: "bittersuess-xyz01.firebaseapp.com",
    databaseURL: "https://bittersuess-xyz01.firebaseio.com",
    projectId: "bittersuess-xyz01",
    storageBucket: "bittersuess-xyz01.appspot.com",
    messagingSenderId: "967129666643",
    appId: "1:967129666643:web:eec9807d7ca66de1",
    uId: "8PrXCHoDVkbRVaORFHL4Lio4evP2"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
