import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TruncatePipe } from '../cp/courses/truncate.pipe';
import { IsProductPipe } from '../is-product.pipe';
import { DateFormatPipe } from '../cp/courses/date-format.pipe';
import { PageTitlePipe } from '../views/ueber/page-title.pipe';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { SanitizePipe } from '../sanitize.pipe';
import { ExternalLinksPipe } from '../external-links.pipe';

@NgModule({
  declarations: [
    TruncatePipe,
    IsProductPipe,
    DateFormatPipe,
    PageTitlePipe,
    SanitizePipe,
    ExternalLinksPipe
  ],
  imports: [
    CommonModule,
    CKEditorModule
  ],
  exports: [
    TruncatePipe,
    IsProductPipe,
    DateFormatPipe,
    SanitizePipe,
    PageTitlePipe,
    CKEditorModule,
    ExternalLinksPipe
  ]
})
export class GlobalModule { }
