import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Observable } from 'rxjs';
import { Product } from 'src/app/cp/Product';
import { FirestoreService } from 'src/app/firestore.service';
import { AngularFirestore } from 'angularfire2/firestore';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';

@Component({
  selector: 'products-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss']
})
export class ProductOverviewComponent implements OnInit {
  items$: Observable<Product[]>;
  category = this.router.url.split("/")[1];
  itemsCount;
  itemRest = [];
  isSaison = false;
  next: string;

  constructor(private db: FirestoreService, private afs: AngularFirestore, private router: Router) {
    // this.router.events.forEach((event) => {
    //   if (event instanceof NavigationStart) {
    //     console.log(this.next)
    //     console.log(event)
    //   }

    // });
  }

  ngOnInit() {
    if (document.querySelector(".main__menu__list__item.menu__active")) document.querySelector(".main__menu__list__item.menu__active").classList.remove("menu__active");
    console.log(this.category)
    this.isSaison = (this.category === "saisonales") ? true : false;
    this.items$ = this.db.productsByCategory(this.category, true);
    this.items$.subscribe(data => {
      // console.log(data)
      this.itemsCount = data.length;
      console.log(this.itemsCount)
      const rest = this.itemsCount % 4;
      console.log(rest)
      for (let i = 0; i < rest; i++) {
        this.itemRest.push("");
      }
    })
  }

  goTo(slug) {
    localStorage.setItem("scrollTo", slug);
    this.router.navigate([`${this.category}/liste`]);
  }

}
