import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-filme',
  templateUrl: './filme.component.html',
  styleUrls: ['./filme.component.scss']
})
export class FilmeComponent implements OnInit {
  lightbox = false;
  active: string;
  iframes = {
    1: "https://www.youtube.com/embed/bTcTwneExU8",
    2: "https://www.youtube.com/embed/qLVNT1MOIK0",
    3: "https://www.youtube.com/embed/_gbnNc3e-Ys",
    4: "https://www.youtube.com/embed/w9ejd0mNR5w",
    5: "https://www.youtube.com/embed/RtzK7JIDYY4",
    6: "https://www.youtube.com/embed/rcg53_4O7GI",
    7: "https://www.youtube.com/embed/3xUMzV1vZpw?rel=0",
    8: "https://www.youtube.com/embed/FUQ6IxuUoYk"
  }

  constructor() { }

  ngOnInit() {
  }

  loadVideo(nr) {
    this.lightbox = true;
    this.active = this.iframes[nr];
  }

}
