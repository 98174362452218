import { Component, OnInit } from '@angular/core';
import { Course, Date } from '../Course';
import { Observable, Subscriber, Subscription } from 'rxjs';
import { AngularFirestore, AngularFirestoreCollection } from 'angularfire2/firestore';
import { ActivatedRoute } from '@angular/router';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { map, take } from 'rxjs/operators';

@Component({
  selector: 'app-update',
  templateUrl: './update.component.html',
  styleUrls: ['./update.component.scss']
})
export class UpdateComponent implements OnInit {
  course$: Observable<any>;
  routeId: string;
  title: string;
  subtitle: string;
  dates: Date[] = [];
  description: string = "";
  active: boolean = true;
  errors = [];
  Editor = ClassicEditor;
  msg = "";

  constructor(private afs: AngularFirestore, private route: ActivatedRoute) { }

  ngOnInit() {
    console.log("uhh");
    this.route.params.subscribe(params => this.routeId = params["id"]);
    console.log(this.routeId);
    this.course$ = this.afs.collection("kurse", ref => ref.where("id", "==", this.routeId)).valueChanges();
    this.setInitial();
  }
  addDate() {
    this.dates.push({ date: "", time: "", status: "" });
  }
  removeDate(idx) {
    console.log(idx)
    this.dates.splice(idx, 1);
  }
  setInitial() {
    this.course$.subscribe(course => {
      this.title = course[0].title;
      this.subtitle = course[0].subtitle;
      this.dates = course[0].dates;
      this.description = course[0].description;
      this.active = course[0].active;

    })
  }
  buildCourse() {
    return {
      title: this.title,
      subtitle: this.subtitle,
      description: this.description,
      dates: this.dates,
      active: this.active
    }
  }
  updateCourse() {
    this.afs.collection("kurse", ref => ref.where("id", "==", this.routeId)).snapshotChanges().pipe(map(changes => {
      const id = changes[0].payload.doc.id;
      this.afs.doc(`kurse/${id}`).update(this.buildCourse()).finally().then(i => console.log(i));
      this.msg = "Done :)";
    })).subscribe()
  }

}
