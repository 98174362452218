import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Course } from '../../courses/Course';
import { FirestoreService } from 'src/app/firestore.service';
import { AngularFirestore } from 'angularfire2/firestore';
import { ScrollingService } from 'src/app/views/scrolling.service';
import { Router } from '@angular/router';
import { Product } from '../../Product';

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.scss']
})
export class ProductListComponent implements OnInit {

  pralinen$: Observable<Product[]>;
  schokolade$: Observable<Product[]>;
  mehr$: Observable<Product[]>;
  saison$: Observable<Product[]>;
  category = this.router.url.split("/")[1];

  constructor(private db: FirestoreService, private afs: AngularFirestore, private router: Router, private scrolling: ScrollingService) {

  }

  ngOnInit() {
    this.pralinen$ = this.db.productsByCategory("pralinen", true);
    this.pralinen$.subscribe(data => {
      // console.log(data)
    })
    this.schokolade$ = this.db.productsByCategory("schokolade", true);
    this.schokolade$.subscribe(data => {
      // console.log(data)
    })
    this.mehr$ = this.db.productsByCategory("mehr", true);
    this.mehr$.subscribe(data => {
      // console.log(data)
    })
    this.saison$ = this.db.productsByCategory("saison", false, true);
    this.saison$.subscribe(data => {
      // console.log(data)
    })
  }

}
