import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Course } from '../Course';
import { FirestoreService } from 'src/app/firestore.service';
import { AngularFirestore } from 'angularfire2/firestore';
import { Router } from '@angular/router';
import { ScrollingService } from 'src/app/views/scrolling.service';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit {
  courses$: Observable<Course[]>;
  category = this.router.url.split("/")[1];

  constructor(private db: FirestoreService, private afs: AngularFirestore, private router: Router, private scrolling: ScrollingService) {

  }

  ngOnInit() {
    this.courses$ = this.db.allCourses();
  }

}
