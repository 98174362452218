import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-ueber',
  templateUrl: './ueber.component.html',
  styleUrls: ['./ueber.component.scss']
})
export class UeberComponent implements OnInit {

  constructor(public route: ActivatedRoute, public router: Router) {
    console.log(this.route.data.subscribe(i => console.log(i)))
  }

  ngOnInit() {
  }

}
