import { Component, OnInit } from '@angular/core';
import { FirestoreService } from 'src/app/firestore.service';
import { AngularFirestore } from 'angularfire2/firestore';
import { Observable } from 'rxjs';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Font from "@ckeditor/ckeditor5-font/src/font";
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class NewsComponent implements OnInit {
  headline: string;
  text: string;
  onOff: boolean;
  isSet = false;
  Editor = ClassicEditor;
  setId: string;
  existingNews: Observable<any>;
  config = {
    // plugins: Font,
    link: {
      decorators: [
        {
          mode: 'manual',
          label: 'Link in neuem Tab öffnen?',
          attributes: {
            target: '_blank'
          },
        }
      ]
    },
    fontColor: {
      colors: [
        {
          color: '#5c452b',
          label: 'Braun'
        },
        {
          color: '#E7599A',
          label: 'Pink'
        },
      ]
    },
    // toolbar: ["bold", "italic", "link", "fontColor"],
  }

  constructor(private firestore: FirestoreService, private afs: AngularFirestore) {
    this.existingNews = this.afs.collection("news").valueChanges();
    this.existingNews.subscribe(data => {
      if (data.length > 0) {
        this.isSet = true;
        this.setId = data[0].id;
        this.existingNews = data[0];
        this.headline = data[0].headline;
        this.text = data[0].text;
        this.onOff = data[0].active;
      }
    });
  }

  ngOnInit() {

  }

  updatedNews() {
    return {
      id: this.setId,
      headline: this.headline,
      text: this.text,
      active: this.onOff
    }
  }

  postNews() {
    console.log(this.headline, this.text, this.onOff)
    const id = this.afs.createId();
    const news = {
      id: id,
      headline: this.headline,
      text: this.text,
      active: this.onOff
    }
    if (!this.isSet) {
      this.firestore.postNews(news);
    } else {
      this.afs.collection("news", ref => ref.where("id", "==", this.setId)).snapshotChanges().pipe(map(changes => {
        const id = changes[0].payload.doc.id;
        this.afs.doc(`news/${id}`).update(this.updatedNews()).finally().then(i => console.log(i));
      })).subscribe()
    }
  }
}
