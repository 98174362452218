import { CoursesComponent } from './courses/courses.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NewsComponent } from './news/news.component';
import { FormsModule } from '@angular/forms';
import { SideNavComponent } from './side-nav/side-nav.component';
import { RouterModule } from '@angular/router';
import { DateFormatPipe } from './courses/date-format.pipe';

import { ProductsComponent } from './products/products.component';
import { ProductListComponent } from './products/product-list/product-list.component';
import { ProductAddComponent } from './products/product-add/product-add.component';
import { CoursesModule } from './courses/courses.module';
import { GlobalModule } from '../global/global.module';
import { MetaInfoComponent } from './meta-info/meta-info.component';

@NgModule({
  declarations: [
    CoursesComponent,
    NewsComponent,
    SideNavComponent,
    ProductsComponent,
    MetaInfoComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    CoursesModule,
    GlobalModule
  ],
  exports: [
    CoursesComponent,
    SideNavComponent,
  ]
})
export class CpModule { }
