import { Component, OnInit } from '@angular/core';
import { Product } from '../../Product';
import { AngularFirestore } from 'angularfire2/firestore';
import { Router } from '@angular/router';
import { FirestoreService } from 'src/app/firestore.service';

@Component({
  selector: 'app-product-add',
  templateUrl: './product-add.component.html',
  styleUrls: ['./product-add.component.scss']
})
export class ProductAddComponent implements OnInit {
  category = this.router.url.split("/")[1];
  name: string;
  slug: string;
  order: number;
  image: string;
  description: string;
  overview = false;
  saison: string;

  constructor(private afs: AngularFirestore, private router: Router, private db: FirestoreService) { }

  ngOnInit() {
  }

  create() {
    const product: Product = {
      order: this.order,
      id: this.afs.createId(),
      title: this.name,
      slug: this.slug,
      subtitle: "",
      image: this.image,
      description: this.description,
      overview: this.overview,
      saison: this.saison 
    }
    console.log(product)
    this.db.addProduct(product, "saison");
  }

}
