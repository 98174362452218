import { Component, OnInit } from '@angular/core';
import { AngularFirestoreCollection, AngularFirestore } from 'angularfire2/firestore';
import { Product } from 'src/app/cp/Product';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { ScrollingService } from '../../scrolling.service';
import { FirestoreService } from 'src/app/firestore.service';

@Component({
  selector: 'kurse-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {
  itemsCollection: AngularFirestoreCollection<any>;
  items$: Observable<any>;
  mobile = false;
  active: string;

  constructor(private afs: AngularFirestore, public router: Router, private scrolling: ScrollingService, private db: FirestoreService) {
    this.itemsCollection = this.afs.collection<any>("kurse", ref => ref.where("active", "==", true).orderBy("order", "asc"));
    this.items$ = this.itemsCollection.valueChanges();
    console.log(this.items$.subscribe(e => console.log(e)))
  }

  ngOnInit() {
    // this.scrolling.inViewportChange.subscribe(data => {
    //   console.log(data)
    //   this.active = data;
    // });
    window.addEventListener("scroll", this.scrollEvent, true);
  }

  scrollEvent = (event: any): void => {
    const bod = event.target;
    const els = Array.from(bod.querySelectorAll(".solo-cnt__item"));
    const navClass = "main__menu__list__item";
    const navActiveClass = "menu__active";
    if (this.router.url.includes("kurse")) {
      els.forEach((el: any) => {
        if (el && this.isInViewport(el)) {
          const active = bod.querySelector(`.${navClass}.${navActiveClass}`);
          // console.log(active, navClass)
          if (active) active.classList.remove(navActiveClass);
          bod.querySelector(`.${navClass}[data-ref='${el.id}']`).classList.add(navActiveClass)
        }
      })
    }
  }

  isInViewport(elem) {
    let bounding = elem.getBoundingClientRect();
    let calc = (window.innerWidth <= 768) ? (bounding.bottom + 300) : (bounding.bottom - 200);
    return (
      bounding.top >= 0 &&
      bounding.left >= 0 &&
      (calc) <= (window.innerHeight || document.documentElement.clientHeight) &&
      bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  };

  scrollTo(destination) {
    // console.log(destination);
    this.scrolling.triggerScrollTo(destination);
  }
  ngOnDestroy(): void {
    // console.log("rm")
    window.addEventListener("scroll", () => {
      return false;
    }, false)
  }
}
