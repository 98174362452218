import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isProduct'
})
export class IsProductPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    const products = ["/pralinen", "/grandcru", "/allerlei", "/bonbonnieren"]
    console.log(products.includes(value))
    return products.includes(value);
  }

}
