import { Component, OnInit } from '@angular/core';
import { Lightbox } from '@ngx-gallery/lightbox';
import { Gallery, GalleryItem, ImageItem, ThumbnailsPosition, ImageSize } from '@ngx-gallery/core';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-impressionen',
  templateUrl: './impressionen.component.html',
  styleUrls: ['./impressionen.component.scss']
})
export class ImpressionenComponent implements OnInit {
  lightbox = false;
  active: string;
  activeNr: number;
  data = [
    {
      srcUrl: '/assets/imgs/ueber-gallery/big/big1.jpg',
      previewUrl: '/assets/imgs/ueber-gallery/thmb1.jpg'
    },
    {
      srcUrl: '/assets/imgs/ueber-gallery/big/big2.jpg',
      previewUrl: '/assets/imgs/ueber-gallery/thmb2.jpg'
    },
    {
      srcUrl: '/assets/imgs/ueber-gallery/big/big3.jpg',
      previewUrl: '/assets/imgs/ueber-gallery/thmb3.jpg'
    },
    {
      srcUrl: '/assets/imgs/ueber-gallery/big/big4.jpg',
      previewUrl: '/assets/imgs/ueber-gallery/thmb4.jpg'
    },
    {
      srcUrl: '/assets/imgs/ueber-gallery/big/big5.jpg',
      previewUrl: '/assets/imgs/ueber-gallery/thmb5.jpg'
    },
    {
      srcUrl: '/assets/imgs/ueber-gallery/big/big6.jpg',
      previewUrl: '/assets/imgs/ueber-gallery/thmb6.jpg'
    },
    {
      srcUrl: '/assets/imgs/ueber-gallery/big/big7.jpg',
      previewUrl: '/assets/imgs/ueber-gallery/thmb7.jpg'
    },
    {
      srcUrl: '/assets/imgs/ueber-gallery/big/big8.jpg',
      previewUrl: '/assets/imgs/ueber-gallery/thmb8.jpg'
    },
    {
      srcUrl: '/assets/imgs/ueber-gallery/big/big9.jpg',
      previewUrl: '/assets/imgs/ueber-gallery/thmb9.jpg'
    },
    {
      srcUrl: '/assets/imgs/ueber-gallery/big/big10.jpg',
      previewUrl: '/assets/imgs/ueber-gallery/thmb10.jpg'
    },
    {
      srcUrl: '/assets/imgs/ueber-gallery/big/big11.jpg',
      previewUrl: '/assets/imgs/ueber-gallery/thmb11.jpg'
    },
  ]
  maxLen = this.data.length - 1;

  constructor() {
  }

  ngOnInit() {
    window.addEventListener("keydown", (e) => {
      if (e.keyCode === 27 && this.lightbox) this.close();
      if (e.keyCode === 37 && this.activeNr > 0 && this.lightbox) this.prevPic(this.activeNr);
      if (e.keyCode === 39 && this.activeNr < this.maxLen && this.lightbox) this.nextPic(this.activeNr);
    })
  }

  loadPic(nr) {
    this.lightbox = true;
    this.active = this.data[nr].srcUrl
    this.activeNr = nr;

  }

  prevPic(nr) {
    console.log("prev")
    this.activeNr = nr - 1;
  }
  nextPic(nr) {
    console.log("next")
    this.activeNr = nr + 1;
  }
  close() {
    this.lightbox = false;
    this.activeNr = 0;
    console.log("close")
  }
}

