import { Injectable } from '@angular/core';


interface Scripts {
  name: string;
  src: string;
}

export const ScriptStore: Scripts[] = [
  { name: 'bookingkit', src: 'https://e32c9d68304342a1d0e7ef59ba5bb145.widget.bookingkit.net/bkscript/79f21916943077ef219674ef45b95dc9' },
  { name: 'product-catalog-widget', src: 'https://widgets.regiondo.net/catalog/v1/catalog-widget.min.js' }
];

@Injectable({
  providedIn: 'root'
})

export class DynamicscriptloaderService {

  private scripts: any = {};

  constructor() {
    ScriptStore.forEach((script: any) => {
      this.scripts[script.name] = {
        loaded: false,
        src: script.src
      };
    });
  }

  load(...scripts: string[]) {
    const promises: any[] = [];
    scripts.forEach((script) => promises.push(this.loadScript(script)));
    return Promise.all(promises);
  }

  loadScript(name: string) {
    return new Promise((resolve, reject) => {
      if (!this.scripts[name].loaded) {
        //load script
        let script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = this.scripts[name].src;
        // script.onload = () => {
        // };
        this.scripts[name].loaded = true;
        // resolve({ script: name, loaded: true, status: 'Loaded' });
        script.onerror = (error: any) => resolve({ script: name, loaded: false, status: 'Loaded' });
        document.querySelector('head').appendChild(script);
      } else {
        resolve({ script: name, loaded: true, status: 'Already Loaded' });
        // script.parentNode.removeChild(script);
      }
      // let script = document.createElement('script');
      // script.type = 'text/javascript';
      // script.src = this.scripts[name].src;
      // script.onload = () => {
      //   this.scripts[name].loaded = true;
      //   resolve({ script: name, loaded: true, status: 'Loaded' });
      // };
      // script.onerror = (error: any) => resolve({ script: name, loaded: false, status: 'Loaded' });
      // document.querySelector('body').appendChild(script);
      // if(this.scripts[name].loaded === true) {
      //   script.parentNode.removeChild(script);
      // }
    });
  }


}
