import {Component} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {AuthenticationService} from './authentication.service';
import {AngularFirestore} from 'angularfire2/firestore';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'bittersuess';
  hasShopLink: Observable<any>;
  shopLink: string;

  constructor(public router: Router, public auth: AuthenticationService, private afs: AngularFirestore) {
    this.hasShopLink = this.afs.collection('meta').valueChanges();
    this.hasShopLink.subscribe(data => {
      if (data.length > 0) {
        this.shopLink = data[0].shopLink;
      }
    });
  }

  logout() {
    this.auth.logout();
  }
}
