import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-auszeichnungen',
  templateUrl: './auszeichnungen.component.html',
  styleUrls: ['./auszeichnungen.component.scss']
})
export class AuszeichnungenComponent implements OnInit {
  lightbox = false;
  active: string;
  activeNr: number;
  data = [
    { srcUrl: "/assets/imgs/auszeichnungen/10-01-AZ-Sesam-Praline.jpg", portrait: true },
    { srcUrl: "/assets/imgs/auszeichnungen/10-02-AZ-Trueffelmischung.jpg", portrait: true },
    { srcUrl: "/assets/imgs/auszeichnungen/14-01-AZ-Sommelier-certificado.jpg", portrait: false },
    { srcUrl: "/assets/imgs/auszeichnungen/14-2-AZ-Chocolatier-certificado.jpg", portrait: false },
    { srcUrl: "/assets/imgs/auszeichnungen/16-01-AZ-Bolivia-Sauvage.jpg", portrait: true },
    { srcUrl: "/assets/imgs/auszeichnungen/16-01-AZ-Noir-Orange.jpg", portrait: true },
    { srcUrl: "/assets/imgs/auszeichnungen/16-02-AZ-Bolivia-Sauvage.jpg", portrait: true },
    { srcUrl: "/assets/imgs/auszeichnungen/16-03-AZ-Centenario-Crudo.jpg", portrait: true },
    { srcUrl: "/assets/imgs/auszeichnungen/17-01-AZ-Meisterstrasze.jpg", portrait: false },
    { srcUrl: "/assets/imgs/auszeichnungen/17-02-AZ-Meisterstrasze.jpg", portrait: false },
    { srcUrl: "/assets/imgs/auszeichnungen/18-01-AZ-DomRep-Sarrapia.jpg", portrait: true },
    { srcUrl: "/assets/imgs/auszeichnungen/18-02-AZ-Indonesion-Tanazo-Flores.jpg", portrait: true },
    { srcUrl: "/assets/imgs/auszeichnungen/18-03-AZ-Wiener-Melange.jpg", portrait: true },
    { srcUrl: "/assets/imgs/auszeichnungen/LM-Pistazzien-Gold-webseite.jpg", portrait: true },
    { srcUrl: "/assets/imgs/auszeichnungen/Centenario-Silber-webseite.jpg", portrait: true },
    { srcUrl: "/assets/imgs/auszeichnungen/ErdbeerKaffee-Silber-webseite.jpg", portrait: true },
    { srcUrl: "/assets/imgs/auszeichnungen/LM-Dulce-de-Leche-Bronze-webseite.jpg", portrait: true },
    { srcUrl: "/assets/imgs/auszeichnungen/LM-helles-Nougat-Bronze-webseite.jpg", portrait: true },
    { srcUrl: "/assets/imgs/auszeichnungen/22-10-Bezirks-Award-Unternehmer-des-Jahres-2022.jpeg", portrait: true },
    { srcUrl: "/assets/imgs/auszeichnungen/22-10-Bezirksblatt-So-sehen-Sioeger-aus.jpeg", portrait: true },
    { srcUrl: "/assets/imgs/auszeichnungen/22-10-Genuss-Award-Gebrannte-Kakaobohnen.jpeg", portrait: true },
    { srcUrl: "/assets/imgs/auszeichnungen/22-10-Genuss-Award-Milch-Nougat-Herzen.jpeg", portrait: true },
    { srcUrl: "/assets/imgs/auszeichnungen/22-10-Unternehmen-Oesterreich-Portrait.jpeg", portrait: true },
  ]
  maxLen = this.data.length - 1;

  constructor() { }

  ngOnInit() {
    console.log(this.activeNr)
    window.addEventListener("keydown", (e) => {
      if (e.keyCode === 27 && this.lightbox) this.close();
      if (e.keyCode === 37 && this.activeNr > 0 && this.lightbox) this.prevPic(this.activeNr);
      if (e.keyCode === 39 && this.activeNr < this.maxLen && this.lightbox) this.nextPic(this.activeNr);
    })
  }
  loadPic(nr) {
    this.lightbox = true;
    this.active = this.data[nr].srcUrl
    this.activeNr = nr;
    console.log(this.activeNr)

  }

  prevPic(nr) {
    console.log("prev")
    this.activeNr = nr - 1;
    console.log(this.activeNr)
  }
  nextPic(nr) {
    console.log("next")
    this.activeNr = nr + 1;
    console.log(this.activeNr)
  }

  close() {
    this.lightbox = false;
    console.log("close")
  }

}
