import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { AddComponent } from './add/add.component';
import { ListComponent } from './list/list.component';
import { FormsModule } from '@angular/forms';
import { GlobalModule } from 'src/app/global/global.module';
import { UpdateComponent } from './update/update.component';

@NgModule({
  declarations: [
    AddComponent,
    ListComponent,
    UpdateComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    GlobalModule
  ]
})
export class CoursesModule { }
