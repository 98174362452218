import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { AngularFirestore } from 'angularfire2/firestore';
import { ActivatedRoute } from '@angular/router';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-update',
  templateUrl: './update.component.html',
  styleUrls: ['./update.component.scss']
})
export class UpdateComponent implements OnInit {
  course$: Observable<any>;
  routeId: string;
  routeCategory: string;
  title: string;
  description: string = "";
  slug: string = "";
  image: string = "";
  order: number = null;
  active: boolean = true;
  errors = [];
  Editor = ClassicEditor;
  msg = "";

  constructor(private afs: AngularFirestore, private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.routeCategory = params["category"]
      this.routeId = params["id"]
    });
    console.log(this.routeId);
    this.course$ = this.afs.collection(this.routeCategory, ref => ref.where("id", "==", this.routeId)).valueChanges();
    this.setInitial();
  }


  setInitial() {
    this.course$.subscribe(course => {
      this.title = course[0].title;
      this.description = course[0].description;
      this.order = course[0].order;
      this.slug = course[0].slug;
      this.image = course[0].image;
    })
  }
  buildCourse() {
    return {
      title: this.title,
      description: this.description,
      order: this.order,
      slug: this.slug,
      image: this.image
    }
  }
  updateCourse() {
    this.afs.collection(this.routeCategory, ref => ref.where("id", "==", this.routeId)).snapshotChanges().pipe(map(changes => {
      const id = changes[0].payload.doc.id;
      this.afs.doc(`${this.routeCategory}/${id}`).update(this.buildCourse()).finally().then(i => console.log(i));
      this.msg = "Done :)";
    })).subscribe()
  }

}
