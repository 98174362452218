import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';

import { Observable, of } from 'rxjs';
import { FirestoreService } from './firestore.service';

interface User {
  name: string;
  password: string;
}


@Injectable({ providedIn: 'root' })
export class AuthenticationService {

  user: Observable<User>;

  constructor(
    private afAuth: AngularFireAuth,
    private firestore: AngularFirestore,
    private router: Router
  ) {
  }

  login(email: string, password: string) {
    return this.afAuth
      .auth
      .signInWithEmailAndPassword(email, password);
  }
  logout() {
    localStorage.removeItem("user");
    localStorage.removeItem("userGroup");
    this.router.navigate(["/login"]);
    // this.afAuth.auth.signOut();
  }

  isUserLoggedIn() {
    let user = localStorage.getItem('user');
    return !(user === null);
  }

  userLogin(name, password) {
    this.login("hi@xschoenberger.com", "!bittersuess.xYz0!").then(i => {
      const userCollection = this.firestore.collection<User>("users", ref => ref.where("name", "==", name));
      const user$ = userCollection.valueChanges();
      let result;
      user$.subscribe((data: any) => {
        if (data.length > 0) {
          if (data[0].name === name && data[0].password === password) {
            localStorage.setItem("user", JSON.stringify(true));
            localStorage.setItem("userGroup", data[0].group);
            this.router.navigate(["/cp/meta-info"]);
            return;
          }
        }
      });
    })
  }
}
