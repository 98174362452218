import { Component, OnInit } from '@angular/core';
import {map} from 'rxjs/operators';
import { AngularFirestore } from 'angularfire2/firestore';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-meta-info',
  templateUrl: './meta-info.component.html',
  styleUrls: ['./meta-info.component.scss']
})
export class MetaInfoComponent implements OnInit {
  hasShopLink: Observable<any>;
  shopLinkId: string;
  shopLink: string;
  isSet = false;

  constructor(private afs: AngularFirestore) {
    this.hasShopLink = this.afs.collection("meta").valueChanges();
    this.hasShopLink.subscribe(data => {
      if (data.length > 0) {
        this.isSet = true;
        this.shopLinkId = data[0].id;
        this.shopLink = data[0].shopLink;
      }
    });
  }

  ngOnInit() {
  }

  postMeta() {
    this.afs.collection("meta", ref => ref.where("id", "==", this.shopLinkId)).snapshotChanges().pipe(map(changes => {
      const id = changes[0].payload.doc.id;
      this.afs.doc(`meta/${id}`).update({shopLink: this.shopLink}).finally().then(i => console.log(i));
    })).subscribe();
  }

}
