import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-three-sixty',
  templateUrl: './three-sixty.component.html',
  styleUrls: ['./three-sixty.component.scss']
})
export class ThreeSixtyComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
