import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { DynamicscriptloaderService } from 'src/app/dynamicscriptloader.service';

@Component({
  selector: 'catalog',
  templateUrl: './catalog.component.html',
  styleUrls: ['./catalog.component.css'],
  providers: [DynamicscriptloaderService]
})

export class Catalog implements OnInit {
  styles = `.regiondo-list-product-button {
    background-color: #FF5FA7;
  }
  
  .regiondo-list-product-button:hover {
    background-color: #FF5FA7;
    color: #FFFFFF;
  }
  
  .Re-c-MuiScopedCssBaseline-root {
    font-family: "Goudy Old Style", serif;
    font-size: 0.900rem
  }
  
  .regiondo-widget .regiondo-button {
    background-color: #FF5FA7;
  }
  
  .regiondo-widget .regiondo-dates:before {
    padding-bottom: 10px;
    color: #7C8490;
    content: 'Wählen Sie ein Datum';
    font-size: 16px;
  }
  
  .regiondo-widget .regiondo-select {
    color: #fff;
  }
  
  .regiondo-widget .regiondo-select option {
    color: #7C8490;
  }
  
  .regiondo-widget .regiondo-select option:first-child {
    display: none;
  }
  
  .regiondo-booking-header {
    color: #5A3F25;
  }
  
  .regiondo-product-header {
    color: #5A3F25;
  }
  
  .regiondo-list-product-header {
    color: #5A3F25;
  }
  
  @media screen {
    .regiondo-booking-mobile-button {
      background-color: #FF5FA7;
    }
  }`;

  @ViewChild("styles") stylesEl: ElementRef;

  constructor(private dynamicScriptLoader: DynamicscriptloaderService, private element: ElementRef) {
    this.loadScripts()
  }

  ngOnInit() {
    this.loadScripts()
  }

  loadScripts() {
    // You can load multiple scripts by just providing the key as argument into load method of the service
    this.dynamicScriptLoader.load('product-catalog-widget').then(data => {
      customElements.whenDefined('product-catalog-widget').then((ev) => {
        const widget = this.element.nativeElement.querySelector('product-catalog-widget')
        const customStylesEl = widget.shadowRoot.querySelector('#styles #custom-styles')
        customStylesEl.innerHTML = `<style>${this.styles}</style>`;
      });
    }).catch(error => console.log(error));
  }
}