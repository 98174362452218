import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'externalLinks'
})
export class ExternalLinksPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    const anchors = value.match(/<a[\s]+([^>]+)>((?:.(?!\<\/a\>))*.)<\/a>/g);
    anchors.filter(a => a.includes(".") && !a.includes("bitter-suess"))
    return value;
  }

}
