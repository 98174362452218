import { Component, OnInit, NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Course } from 'src/app/cp/courses/Course';
import { FirestoreService } from 'src/app/firestore.service';
import { AngularFirestore } from 'angularfire2/firestore';
import { ScrollingService } from '../scrolling.service';
import { DynamicscriptloaderService } from 'src/app/dynamicscriptloader.service';

@Component({
  selector: 'app-courses',
  templateUrl: './courses.component.html',
  styleUrls: ['./courses.component.scss'],
  providers: [DynamicscriptloaderService]
})

export class CoursesComponent implements OnInit {
  courses$: Observable<Course[]>;
  category = this.router.url.split("/")[1];

  constructor(private db: FirestoreService, private afs: AngularFirestore, public router: Router, private scrolling: ScrollingService, private dynamicScriptLoader: DynamicscriptloaderService) {
  }

  ngOnInit() {
    //
  }

  loadScripts() {
    // You can load multiple scripts by just providing the key as argument into load method of the service
    this.dynamicScriptLoader.load('bookingkit').then(data => {
      // Script Loaded Successfully
    }).catch(error => console.log(error));
  }

}
