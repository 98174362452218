import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-datenschutzerklaerung',
  templateUrl: './datenschutzerklaerung.component.html',
  styleUrls: ['./datenschutzerklaerung.component.scss']
})
export class DatenschutzerklaerungComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
