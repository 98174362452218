import { ListComponent as CourseList } from './cp/courses/list/list.component';
import { PresseComponent } from './views/ueber/presse/presse.component';
import { NewsComponent } from './cp/news/news.component';
import { LoginComponent } from './login/login.component';
import { KontaktComponent } from './views/ueber/kontakt/kontakt.component';
import { HomeComponent } from './views/home/home.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UeberComponent } from './views/ueber/ueber.component';
import { ProductsComponent } from './views/products/products.component';
import { ProductsComponent as CpProductsComponent } from './cp/products/products.component';
import { ListComponent } from './views/products/list/list.component';
import { CpComponent } from './cp/cp.component';
import { AuthGuard } from './auth-guard.guard';
import { ImpressionenComponent } from './views/ueber/impressionen/impressionen.component';
import { AuszeichnungenComponent } from './views/ueber/auszeichnungen/auszeichnungen.component';
import { ThreeSixtyComponent } from './views/ueber/three-sixty/three-sixty.component';
import { FilmeComponent } from './views/ueber/filme/filme.component';
import { GesaComponent } from './views/ueber/gesa/gesa.component';
import { AddComponent } from './cp/courses/add/add.component';
import { CoursesComponent as CpCoursesComponent } from './cp/courses/courses.component';
import { ProductListComponent } from './cp/products/product-list/product-list.component';
import { ProductAddComponent } from './cp/products/product-add/product-add.component';
import { ProductOverviewComponent } from './views/products/overview/overview.component';
import { CoursesComponent } from './views/courses/courses.component';
import { UpdateComponent } from './cp/courses/update/update.component';
import { UpdateComponent as ProductUpdate } from './cp/products/update/update.component';
import { ImpressumComponent } from './views/ueber/impressum/impressum.component';
// import { BioComponent } from './views/ueber/bio/bio.component';
import { AllergeneComponent } from './views/ueber/allergene/allergene.component';
import { HaftungsausschlussComponent } from './views/ueber/haftungsausschluss/haftungsausschluss.component';
import { DatenschutzerklaerungComponent } from './views/ueber/datenschutzerklaerung/datenschutzerklaerung.component';
import { FirmenkundenComponent } from './views/firmenkunden/firmenkunden.component';
import { IntroComponent } from './views/firmenkunden/intro/intro.component';
import {MetaInfoComponent} from './cp/meta-info/meta-info.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'login', component: LoginComponent },
  {
    path: 'pralinen', component: ProductsComponent,
    children: [
      { path: '', component: ProductOverviewComponent },
      { path: 'liste', component: ListComponent }
    ]
  },
  {
    path: 'schokolade', component: ProductsComponent,
    children: [
      { path: '', component: ProductOverviewComponent },
      { path: 'liste', component: ListComponent }
    ]
  },
  {
    path: 'mehr', component: ProductsComponent,
    children: [
      { path: '', component: ProductOverviewComponent },
      { path: 'liste', component: ListComponent }
    ]
  },
  {
    path: 'saisonales', component: ProductsComponent,
    children: [
      { path: '', component: ProductOverviewComponent },
      { path: 'xmas', component: ListComponent },
      { path: 'valentin', component: ListComponent },
      { path: 'ostern', component: ListComponent },
    ]
  },
  {
    path: 'kurse', component: CoursesComponent
  },

  {
    path: 'firmenkunden', component: FirmenkundenComponent, children: [
      { path: '', component: IntroComponent }
    ]
  },

  {
    path: 'ueber', component: UeberComponent, children: [
      { path: '', component: GesaComponent, data: { title: 'über bitter suess' } },
      { path: 'kontakt', component: KontaktComponent, data: { title: 'kontakt' } },
      { path: 'impressionen', component: ImpressionenComponent },
      { path: 'auszeichnungen', component: AuszeichnungenComponent },
      { path: '360', component: ThreeSixtyComponent },
      { path: 'filme', component: FilmeComponent },
      { path: 'presse', component: PresseComponent },
      { path: 'impressum', component: ImpressumComponent },
      // { path: 'bio-nachhaltigkeit', component: BioComponent },
      { path: 'allergene', component: AllergeneComponent },
      { path: 'datenschutzerklaerung', component: DatenschutzerklaerungComponent },
      { path: 'haftungsausschluss', component: HaftungsausschlussComponent },
    ]
  },
  {
    path: 'cp', component: CpComponent, canActivate: [AuthGuard],
    children: [
      { path: 'meta-info', component: MetaInfoComponent },
      { path: 'news', component: NewsComponent },
      {
        path: 'kurse', component: CpCoursesComponent, children: [
          { path: '', component: CourseList },
          { path: 'add', component: AddComponent },
          { path: 'edit/:id', component: UpdateComponent },
        ]
      },
      {
        path: 'products', component: CpProductsComponent, children: [
          { path: '', component: ProductListComponent },
          { path: 'add', component: ProductAddComponent },
          { path: 'edit/:category/:id', component: ProductUpdate }
        ]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
